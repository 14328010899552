import { Typography } from "@mui/material";
import {
  FIVE_HUNDRED_MEGABYTES_IN_BYTES,
  FIVE_MEGABYTES_IN_BYTES,
  TEXT_FILE_TYPES,
  type FileReference
} from "@toolflow/shared";
import { createContext, ReactNode, useContext, useState } from "react";
import { ErrorCode, useDropzone, type Accept } from "react-dropzone";
import { UploadIcon } from "../../../globalTheme/icons/icons";
import CircleIcon from "../../../utilities/components/icons/CircleButton";
import { parsedClassNames } from "../../../utilities/functions/parsedClassNames";
import styles from "./fileDropzone.module.css";
import { useDispatchFileRejectionError } from "./hooks/useDispatchFileRejectionError";
import useDragListener from "./hooks/useDragListener";

const cx = parsedClassNames.bind(styles);

export function isRawFile(file: File | FileReference): file is File {
  return (
    !!(file as File).name && !!(file as File).size && !!(file as File).type
  );
}

interface FullScreenFileDropzone {
  files: (File | FileReference)[];
  acceptedExtensions?: Accept;
  uploadInProgress: boolean;
  addFiles(files: File[]): void;
  removeFile(index: number): void;
  updateFile(index: number, newFile: FileReference | null): void;
}

const FullScreenFileDropzoneContext =
  createContext<FullScreenFileDropzone | null>(null);

export default function FullScreenFileDropzoneProvider({
  className,
  dropLabel = "",
  helperText = "",
  acceptedExtensions,
  children
}: {
  className?: string;
  dropLabel?: string;
  helperText?: string;
  acceptedExtensions?: Accept;
  children?: ReactNode;
}) {
  const isDragging = useDragListener();
  const onDropRejected = useDispatchFileRejectionError();

  const [files, setFiles] = useState<(File | FileReference)[]>([]);

  const uploadInProgress = files.some((file) => isRawFile(file));

  function addFiles(droppedFiles: File[]) {
    setFiles((prev) => {
      return [...prev, ...droppedFiles];
    });
  }

  function removeFile(index: number) {
    setFiles((prev) => prev.filter((_, idx) => idx !== index));
  }

  function updateFile(index: number, newFile: FileReference | null) {
    if (!!newFile) {
      setFiles((prev) =>
        prev.map((oldFile, idx) => (idx === index ? newFile : oldFile))
      );
    } else {
      setFiles((prev) => prev.filter((_, idx) => idx !== index));
    }
  }

  const { getRootProps, getInputProps, open } = useDropzone({
    noClick: true,
    noKeyboard: true,
    accept: acceptedExtensions,
    maxSize: FIVE_HUNDRED_MEGABYTES_IN_BYTES,
    onDrop: addFiles,
    onDropRejected,
    validator(file) {
      if (
        Object.keys(TEXT_FILE_TYPES).includes(file.type) &&
        file.size > FIVE_MEGABYTES_IN_BYTES
      ) {
        return {
          code: ErrorCode.FileTooLarge,
          message: `${FIVE_MEGABYTES_IN_BYTES}`
        };
      }
      return null;
    }
  });

  return (
    <FullScreenFileDropzoneContext.Provider
      value={{
        files,
        uploadInProgress,
        acceptedExtensions,
        addFiles,
        removeFile,
        updateFile
      }}
    >
      {children}
      {isDragging && (
        <div
          {...getRootProps()}
          className={cx(styles["full-dropzone"], className)}
          onClick={open}
        >
          <input {...getInputProps()} />
          <CircleIcon size={20} Icon={UploadIcon} />
          <Typography
            align="center"
            color="white"
            variant="h8"
            className="m-t-8px"
          >
            {dropLabel}
          </Typography>
          <Typography
            variant="body2"
            align="center"
            sx={{ color: (theme) => theme.palette.text.secondary }}
          >
            {helperText}
          </Typography>
        </div>
      )}
    </FullScreenFileDropzoneContext.Provider>
  );
}

export function useFilesFromFullScreenDropzone() {
  const values = useContext(FullScreenFileDropzoneContext);
  if (!values) {
    throw new Error("Cannot get files from outside of dropzone context.");
  }
  return values;
}
