import { ButtonBase, Typography } from "@mui/material";
import {
  AUDIO_FILE_TYPES,
  getFileExtensions,
  TEXT_FILE_TYPES,
  VIDEO_FILE_TYPES
} from "@toolflow/shared";
import { useRef } from "react";
import { AttachmentLine } from "../../../../../../../globalTheme/icons/icons";
import { toolflowTextDisabledColor } from "../../../../../../../globalTheme/muiUtils/appTheme";
import { useFilesFromFullScreenDropzone } from "../../../../../../files/fileUpload/FullScreenFileDropzone";

export default function AddFileButton() {
  const inputRef = useRef<HTMLInputElement | null>();
  const { addFiles } = useFilesFromFullScreenDropzone();

  return (
    <ButtonBase
      className="m-r-8px"
      onClick={() => {
        inputRef.current?.click();
      }}
    >
      <input
        ref={(r) => (inputRef.current = r)}
        type="file"
        accept={getFileExtensions({
          ...AUDIO_FILE_TYPES,
          ...VIDEO_FILE_TYPES,
          ...TEXT_FILE_TYPES
        }).join(",")}
        hidden
        onChange={(e) => {
          if (e.target.files) addFiles([...e.target.files]);
        }}
      />
      <AttachmentLine size={14} color={toolflowTextDisabledColor} />
      <Typography className="text-transform-none m-l-4px" variant="smallButton">
        Files
      </Typography>
    </ButtonBase>
  );
}
